import Vue from 'vue'
import Vuex from 'vuex'
import user from "./modules/user"

import getters from "./getters";

import history from './modules/history'

Vue.use(Vuex)

const modules= {
    user,
    history
}

export default new Vuex.Store({
	modules,
	getters
})