import Vue from "vue";
import App from "./App.vue";
import router from "./router/index";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "@/assets/index.css";
import "default-passive-events";
import store from "./store/index";

// import '@vueup/vue-quill/dist/vue-quill.core.css'; // 引入样式
// import { QuillEditor } from '@vueup/vue-quill';
// Vue.component('QuillEditor', QuillEditor);

import VueQuillEditor from 'vue-quill-editor'

// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

Vue.use(VueQuillEditor, /* { default global options } */)

//引入swiper样式
import "swiper/css/swiper.css";
import FilterBox from "@/components/FilterBox";

Vue.config.productionTip = false;
Vue.use(ElementUI);

import VScaleScreen from "v-scale-screen";
Vue.use(VScaleScreen);

Vue.component("FilterBox", FilterBox);
Vue.config.errorHandler = function (error, vm, info) {
  console.error("Error:", error);
};

export default new Vue({
  render: (h) => h(App),
  router,
  store,
}).$mount("#app");
