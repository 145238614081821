//统一接口管理
import requests from "@/api/request";
import requests1 from "@/api/request1";
 
//登录
export const reqLogin = (data) =>
  requests({ url: "/user/login", method: "post", data });
//修改密码
export const reqChangePassword = (data) =>
  requests({ url: "/sysUser/updatePassword", method: "post", data });
//获取商户id
export const reqCompanyInfoList = () => requests({url:'/billCompany/getCompanyInfoList',method:'get'})

//首页接口..........
//首页数据统计 /homePage/fristCom
export const reqHomeInfo = (data) =>
  requests({
    url: "/homePage/homeCountInfo",
    method: "post",
    data,
  });
//回收箱信息接口
export const reqBoxData = (data) =>
  requests({ url: "/homePage/boxTotalCount", method: "post", data });
//用户统计柱状图
export const reqUserData = (data) =>
  requests({ url: "/homePage/userCount", method: "post", data });
//订单统计曲线图
export const reqOrderData = (data) =>
  requests({ url: "/homePage/orderCount", method: "post", data });
//重量统计
export const reqBoxWeightCountVo = () =>
  requests({ url: "/homePage/getBoxWeightCountVo", method: "post", data: {} });
//金额统计
export const reqMoney = () =>
  requests({
    url: "/recyclingBinStatisticsa/getFirst",
    method: "post",
    data: {},
  });

//人员管理页面.................
//添加清运员
export const reqAddBoxCleaner= (data) => requests({url:'/boxCleaner/addBoxCleaner',method:'post',data})
//修改清运员/转运员
export const reqUpdateById = (data) => requests({url:'/boxCleaner/updateById',method:'post',data})
//删除清运员/转运员
export const reqDelete = (data) => requests({url:'/boxCleaner/deleteById',method:'post',data})
//回收员清运订单扣费
export const koukuanJdorders = (data) => requests({url:'boxCleaner/koukuanJdorders',method: 'post',data})


//用户信息列表
export const reqUserInfo = (data) =>
  requests({ url: "/litemallUser/list", method: "post", data });
//用户拉黑恢复
export const reqBlacklist = (data) =>
  requests({ url: "/litemallUser/setBlacklist", method: "post", data });
//信用分恢复
export const reqRecoveryCredit = (id) =>
  requests({
    url: "/litemallUser/recoveryCredit",
    method: "post",
    data: { id },
  });
//用户信息个人账户详情
export const reqAllMes = (data) =>
  requests({ url: "/billUser/getAllMes", method: "post", data });
//
//投递地点
export const reqUserUseBossMesList = (data) =>
  requests({ url: "/billUser/getUserUseBossMesList", method: "post", data });
//账单明细
export const reqUserUseBossMesList2 = (data) =>
  requests({ url: "/billUser/getUserUseBossMesList2", method: "post", data });
//用户投递记录
export const reqOrderMseList = (data) =>
  requests({ url: "/billUser/getOrderMseList", method: "post", data });
export const reqUnauditedOrderVo = (data) =>
  requests({ url: "/boxUserOrder/getUnauditedOrderVo", method: "post", data });
export const reqUnauditedOrderVo1 = (data) =>
  requests({ url: "/litemallUser/getUnauditedOrderVo", method: "post", data });
//违规记录
export const reqAuditedOrderVo = (data) => requests({url:'/litemallUser/getAuditedOrderVo',method:'post',data})
//人员表格信息 通用
export const reqCleanerData = (data) =>
  requests({ url: "/boxCleaner/list/", method: "post", data });

//订单管理页面表格接口...............
//投放订单列表 订单审核
//分拣问题上报列表
export const reqFjReportList = (data) =>
    requests({ url: "/boxUserOrder/getBoxUserOrderReportVos/", method: "post" ,data});

export const reqFjReportUpdate = (data) =>
    requests({ url: "/boxUserOrder/updateBoxUserOrderReportVo/", method: "post" ,data});


//问题订单列表
export const reqProblemData = (data) =>
  requests({ url: "/boxUserOrder/getProblemList/", method: "post", data });
//上报订单弹框
export const reqReportList = (id) =>
  requests({ url: `/boxUserOrder/getReportList?id=${id}`, method: "get" });
export const reqDeliveryData = (data) =>
  requests({ url: "/boxUserOrder/list/", method: "post", data });
//投放订单新建
export const reqSaveBoxUserOrder = (data) =>
  requests({ url: "/boxUserOrder/saveBoxUserOrder", method: "post", data });
//订单审核照片查看接口
export const reqOrderImg = (data) =>
  requests({ url: "/boxUserOrder/getBoxUserOrderVo", method: "post", data });
//审核上传
export const reqAuditCommit = (data) =>
  requests({ url: "/boxUserOrder/boxOrderAudit", method: "post", data });
//清运订单  转运订单
export const reqCleanerOrder = (data) =>
  requests({ url: "/boxCleanOrder/list/", method: "post", data });
//清运订单弹窗接口
export const reqCleanerOrderDetail = (data) =>
  requests({
    url: "/boxCleanOrder/selOrderBoxMesLisByCleanOrderBoxSn",
    method: "post",
    data,
  });

//设备信息.................
// 设备管理
export const reqInformationData = (data) =>
  requests({ url: "/boxInfo/list/", method: "post", data });
//回收箱启用/关闭
export const reqOpenCloseBox = (data) =>
  requests({ url: "/boxInfo/updateStatus", method: "post", data });
//回收箱开关灯，开关门，刷新，重启
export const reqRemoteOperation = (data) => requests({url:'/boxInfo/remoteOperation',method:'post',data})
//实时视频，视频回放
export const reqBoxVideoUrl = (data) => requests({url:'/boxInfo/getBoxVideoUrl',method:'post',data})
//抓拍图片
export const reqRemoteCapture = (data) => requests({url:'/boxInfo/remoteCapture',method:'post',data})
//在线离线满溢总数
export const reqBoxMesList = (data) => requests({url:'/boxInfo/getBoxMesList',method:'post',data})




//运营管理.................
//设备运营总数统计
export const reqEquipmentOperations = () =>
  requests({ url: "/equipmentStatistics/getBoxInfoCountVo", method: "get" });
//获取区域下拉框数据
export const reqAreaNameList = () =>
  requests({ url: "/equipmentStatistics/getAreaNameList", method: "get" });
//获取每日数据统计
export const reqDayInfo = (data) =>
  requests({ url: "/equipmentStatistics/getBoxInfos", method: "post", data });
//获取设备运营列表
export const reqTableList = (data) =>
  requests({ url: "/equipmentStatistics/list", method: "post", data });
//弹窗数据
export const reqDialogTable = (data) =>
  requests({
    url: "/equipmentStatistics/getBoxUseMesList",
    method: "post",
    data,
  });
//清运运营-清运总数据
export const reqCleanTotal = (data) =>
  requests({ url: "/cleanOperation/getAllCleMes1", method: "post", data });
//清运运营-清运数据表
export const reqClearnList = (data) =>
  requests({ url: "/cleanOperation/getCleanPerList", method: "post", data });
//清运重量/未清运重量
export const reqClearnAndNotClearn = (data) =>
  requests({ url: "/cleanOperation/getAllCleMes2", method: "post", data });
//清运员数据
export const reqClearnerList = (data) =>
  requests({ url: "/cleanOperation/getCleanerMse", method: "post", data });
//清运员姓名
export const reqUserName = () =>
  requests({ url: "/cleanOperation/getUserLists", method: "post", data: {} });





//互动列表数据 
export const reqGetCmcActive = (data) => requests({ url: "/cmcRankActive/getCmcActive", method: "post", data });
//确认当期活动结束，并进行活动奖励结算发放
export const reqConfirmActiveRecord= (data) => requests({url:'/cmcRankActive/confirmActiveRecord',method:'post',data});
//修改活动动内容
export const reqUpdateCmcActive = (data) => requests({url:'/cmcRankActive/updateCmcActive',method:'post',data});
//修改活动中奖用户动内容
export const reqUpdateActiveRecord = (data) => requests({url:'/cmcRankActive/updateActiveRecord',method:'post',data});
//活动中奖用户列表
export const reqActiveRecords = (data) => requests({url:'/cmcRankActive/activeRecords',method:'post',data});




//财务管理.....................
//用户账户
export const reqUserAccounts = (data) =>
  requests({ url: "/billUser/list", method: "post", data });
//积分情况
export const reqJiFenInfo = (data) =>
  requests({ url: "/billUser/getJifenqingkuang", method: "post", data });
//清运员账户
export const reqCleanerAccounts = (data) =>
  requests({ url: "/billCleaner/list", method: "post", data });
//公司账户
export const reqCoAccounts = (data) =>
  requests({ url: "/billCompany/list", method: "post", data });
//回收想资金动向 回收箱收支总数据
export const reqBoxTotalList = () =>
  requests({
    url: "/recyclingBinStatisticsa/getFirst",
    method: "post",
    data: {},
  });
//回收箱收支数据表
export const reqIncomeExpensesList = (data) =>
  requests({ url: "/recyclingBinStatisticsa/getSecond", method: "post", data });
//回收箱曲线图
export const reqEchartsData = (data) =>
  requests({ url: "/recyclingBinStatisticsa/getThird", method: "post", data });

//系统管理.......................
//权限管理
export const reqRouterAdmin = (id) =>
  requests({ url: `/menu/findMenuTree?userId=${id}`, method: "get" });
//获取角色
export const reqUserList = (type) =>
  requests({ url: `/sysUser/getUserList?roleType=${type}`, method: "get" });
//获取用户权限
export const reqUserAdmin = (id) =>
  requests({ url: `/sysUser/findUserMenus?userId=${id}`, method: "get" });
//上传用户权限
export const reqSaveRoleMenus = (data) =>
  requests({ url: "/sysUser/saveUserMenus", method: "post", data });
//新建用户
export const reqSaveNewUser = (data) =>
  requests({ url: "/sysUser/save", method: "post", data });
//删除角色
export const reqDelUser = (data) => requests({ url: "/sysUser/deleteUser", method: "post", data });
//系统日志表格
export const reqLogList = (data) => requests({url:'/sysLog/getSysLogList',method:'post',data})
//批量操作
export const reqOperateBatch = (data) => requests({url:'/sysLog/operateBatch', method:'post',data})
//收藏
export const reqOperateCollect = (data) => requests({url:'/sysLog/operateCollect',method:'post',data})
//客服审核列表
export const reqBoxUserOrderRecord = (data) => requests({url:'/boxUserOrder/getBoxUserOrderRecord',method:'post',data})
//问题建议与反馈
export const reqFeedbackList = (data) => requests({url:'/problemFeedbackForm/list',method:'post',data})
//问题已读
export const reqFeedbackUpdate = (data) => requests({url:'/problemFeedbackForm/update',method:'post',data})



//京东订单
export const reqJdOrderList = (data) => requests({url:'/jdOrder/list',method:'post',data})
export const reqJdorderKoukuan = (data) => requests({url:'/jdOrder/jdOrderKoukuan',method:'post',data})


//metro  sysset
export const reqGetCustomForm = (data) => requests({url:'/boxsysset/getCustomForm',method:'get'})
export const reqGetBaseForm = (data) => requests({url:'/boxsysset/getBaseForm',method:'get'})
export const reqGetAppSetForm = (data) => requests({url:'/boxsysset/getAppSetForm',method:'get'})
export const reqGetRewardsSetForm = (data) => requests({url:'/boxsysset/getRewardsSetForm',method:'get'})
export const reqGetArticleForm = (data) => requests({url:'/boxsysset/getArticleForm',method:'get'})

export const reqUpdateCustomForm = (data) => requests({url:'/boxsysset/updateCustomForm',method:'post',data})
export const reqUpdateBaseForm = (data) => requests({url:'/boxsysset/updateBaseForm',method:'post',data})
export const reqUpdateAppSetForm = (data) => requests({url:'/boxsysset/updateAppSetForm',method:'post',data})
export const reqUpdateRewardsSetForm = (data) => requests({url:'/boxsysset/updateRewardsSetForm',method:'post',data})
export const reqUpdateArticleForm = (data) => requests({url:'/boxsysset/updateArticleForm',method:'post',data})

//设备列表
export const reqGetMetroUserOrderGoods = (data) => requests({url:'/metroUserOrderGoods/getList',method:'post',data})
export const reqDeleteMetroUserOrderGoods = (data) => requests({url:'/metroUserOrderGoods/delete',method:'post',data})

//metro 会员管理
//用户信息列表
export const reqUserInfoMetro = (data) =>  requests({ url: "/litemallUser/list", method: "post", data });
export const reqSalerInfoMetro = (data) =>  requests({ url: "/litemallUser/salerlist", method: "post", data });
export const reqBillUserList = (data) => requests({url:'/billUser/billlist',method:'post',data})

export const reqUserBankCardList = (data) => requests({url:'/metroUserBank/getList',method:'post',data})
export const reqDeleteBankInfo = (data) => requests({url:'/metroUserBank/deletBank',method:'post',data})
export const reqUpdateBankInfo = (data) => requests({url:'/metroUserBank/updateBank',method:'post',data})

export const reqUpdateUserInfo = (data) => requests({url:'/litemallUser/updateUserInfo',method:'post',data})
export const reqUserDetailInfo = (data) => requests({url:'/litemallUser/userDetailInfo',method:'post',data})

//metro 首页数据表
export const reqIndexCountInfo = (data) => requests({url:'/indexinfo/countInfo',method:'post',data})

export const reqMetroOrderData = (data) =>
  requests({ url: "/homePage/orderCount", method: "post", data });

export const reqMetroMoneyData = (data) =>
  requests({ url: "/homePage/orderMoneyCount", method: "post", data });
// export const reqIndexMoneyInfo = (data) => requests({url:'/indexinfo/moneyInfo',method:'post',data})


export const reqSetBlacklist = (data) =>
  requests({ url: "/litemallUser/setBlacklist", method: "post", data });

export const reqRemoveBlacklist = (data) =>
  requests({ url: "/litemallUser/removeBlacklist", method: "post", data });

export const reqSendMoney = (data) =>
  requests({ url: "/litemallUser/addUserBalance", method: "post", data });

export const reqAddUserGoods = (data) =>
  requests({ url: "/litemallUser/addUserGoods", method: "post", data });

export const reqSetUserAdmin = (data) =>
  requests({ url: "/litemallUser/setUserAdmin", method: "post", data });

//sys goods 
export const reqLitemallGoods = () => requests({ url: "/litemallGoods/list", method: "get" });

//metro 邀请排行榜单
export const reqGetLitemallInvitation = (data) => requests({url:'/litemallUser/invitationRank',method:'get'})
export const reqInvitationChargeRank = (data) => requests({url:'/litemallUser/invitationChargeRank',method:'get'})
export const reqGetLitemallInvitationBuy = (data) => requests({url:'/litemallUser/invitationBuyRank',method:'get'})

//metro index api
export const reqWaitOrders = (data) => requests({url:'/indexinfo/waitorders',method:'POST'})


//metro 提现管理
export const reqUserWithdrawList = (data) => requests({url:'/litemallWithdrawOrder/list',method:'post',data})
export const reqSumOrders = (data) => requests({url:'/litemallWithdrawOrder/sumOrders',method:'get'})
export const reqShenheOrders = (data) => requests({url:'/litemallWithdrawOrder/shenhe',method:'POST',data})


export const reqUserChargeList = (data) => requests({url:'/litemallChargeOrder/list',method:'post',data})
export const reqChargeUpdate = (data) => requests({url:'/litemallChargeOrder/updateStatus',method:'post',data})
export const reqSumChargeOrders = (data) => requests({url:'/litemallChargeOrder/sumOrders',method:'get'})
export const reqSubmitChargeInfo = (data) => requests({url:'/litemallChargeOrder/submitInfo',method:'POST',data})
//metro 系统银行卡
export const reqSysBankList = (data) => requests({url:'/metroSysBank/getList',method:'post',data})
export const reqSysBankUpdate = (data) => requests({url:'/metroSysBank/update',method:'post',data})




////数据大屏数据
////总数居

//投放数据区域排名，每日与总数
export const reqScreenData1 = () => requests1({ url: `/screen/getScreenData1`, method: "get" });
//实时投放信息数据列表
export const reqScreenData2 = () => requests1({ url: `/screen/getScreenData2`, method: "get" });
//投放订单数据统计
export const reqScreenData3 = (data) => requests1({url:'/screen/getScreenData3',method:'post',data})
//实时数据统计
export const reqScreenData4 = () => requests1({ url: `/screen/getScreenData4`, method: "get" });
//设备铺设数据统计
export const reqScreenData5 = (data) => requests1({url:'/screen/getScreenData5',method:'post',data})
//用户增长数据统计
export const reqScreenData6 = (data) => requests1({url:'/screen/getScreenData6',method:'post',data})
//实时数据统计
export const reqScreenData7 = () => requests1({ url: `/screen/getScreenData7`, method: "get" });
//获取总区域名称
export const reqScreenData9 = () => requests1({ url: `/screen/getScreenData9`, method: "get" });